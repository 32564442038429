<template>
  <div class="exhibitorsDetails">
    <div class="content">
      <div class="header">
        <img src="@/assets/banner.png"
             alt=""
             class="imgtop">
      </div>
      <div class="introduce">
        <div class="toure">
          <div class="contentFlex">
            <div class="contenLeft">
              <img :src="ContentDate.logo"
                   alt="">
            </div>
            <div class="contenRight">
              <h5>{{ContentDate.company}}</h5>
              <ul class="rightBom">
                <li>品类：{{ContentDate.title}}</li>
                <li>{{ContentDate.pavilion_name}}</li>
              </ul>
            </div>
          </div>
          <div class="flexContent">
            <li>展馆：{{ContentDate.name}}</li>
            <li>展区：{{ContentDate.area_name}}</li>
            <li>展位：{{ContentDate.exhibition_num}}</li>
          </div>
        </div>
        <div class="overContent">
          <p v-html="ContentDate.content"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  exhibitorInfo,
} from "@/api/index";
export default {
  data () {
    return {
      content: '',
      ContentDate: [],

    }
  },
  watch: {
    $route: {
      handler (e) {
        console.log(e, 5555555)
        this.DatetroInfo();
      },
      deep: true,
      immediate: true,
    }
  },
  created () {
  },
  updated () {
  },
  methods: {
    DatetroInfo () {
      let datas = {
        id: this.$route.query.id
      }
      exhibitorInfo(datas).then(res => {
        this.ContentDate = res.data;
      })
    }
  }
}
</script>

<style scoped lang="less">
.exhibitorsDetails {
  background: #fff;
}
.header {
  position: relative;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
  }
}
.rightBom {
  li {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 28px;
    &:last-child {
      color: #48a15e;
    }
  }
}
.overContent {
  word-wrap: break-word;
  text-align: left;
}
.contentFlex {
  display: flex;
  background: #fff;
  position: relative;
  border-radius: 10px;
  padding: 10px;
}
.contenLeft {
  position: relative;
  overflow: hidden;
  box-shadow: 0px 0px 5px 2px rgba(161, 161, 161, 0.1);
  border-radius: 10px;
  img {
    width: 100%;
  }
}
.contenRight {
  margin: 0 auto;
  padding-left: 5px;
  min-width: 222px;
  height: 80px;
  margin-right: 0;
  h5 {
    margin: 0;
    text-align: left;
    padding: 0 0 0 5px;
    font-size: 16px;
  }
  ul {
    justify-content: left;
    font-size: 14px;
    color: #838383;
    padding: 5px 0 0 5px;
    li {
      width: 100%;
    }
  }
  li {
    list-style: none;
    font-size: 14px;
    color: #838383;
    text-align: left;
    &:last-child {
      padding-bottom: 3px;
    }
  }
}
.imgtop {
  width: 100%;
  height: 200px;
}
.introduce {
  width: 90%;
  height: 600px;
  margin: 0 auto;
  margin-top: 10px;
  background: #fff;
}
.toure {
  position: relative;
  z-index: 5;
  background: #fff;
  border-radius: 7px;
}
.flexContent {
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
  text-align: left;
  margin-top: 20px;
  li {
    flex: 1.2;
    list-style: none;
    font-size: 13px;
    &:nth-of-type(2) {
      flex: 2;
    }
    &:nth-of-type(3) {
      flex: 2;
    }
  }
}
</style>
